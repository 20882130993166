/* everywhere else */
* {
  font-family: Arial, Helvetica, sans-serif;
}

.hand-cursor{
  cursor: pointer;
}

a:hover{
  text-decoration: none !important;
}

.top{
  margin-bottom: 30px;
}

.tooltip-inner {
  background-color: var(--dark);
  font-weight: bold;
  color: var(--light);
}
.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: var(--dark) !important;
}
.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: var(--dark) !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: var(--dark) !important;
}
.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: var(--dark) !important;
}

/* disable selection on app */
* {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
}

/* card columns css */
.card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    
}

.card-columns .card {  
  column-break-inside: avoid;  
  display: inline-block;  
  width: 100%;  
}
/* Small devices */
@media (max-width: 320px) { 
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .container {
    max-width: 100% !important;
  }

  .card-columns {
    column-gap: 0.25rem;
  }
  
  .starring-seller{
    font-size: 11px;
  }

  .prime{
    font-size: 9px;
    cursor: pointer;
  }

  .updated-ago{
    line-height: 13px;
    font-size: 9px;
  }
  .order-option{
    max-width: 136px;
  }
  .banner .bone.player {
    width: 100%;
    height: 69px;
  }

  .updated-ago > .text-truncate{
    max-width: 110px;
  }

}

/* Small devices Moto G4 */
@media (max-width: 360px) { 
  .card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
  .container {
    max-width: 100% !important;
  }

  .card-columns {
    column-gap: 0.25rem;
  }
  
  .starring-seller{
    font-size: 11px;
  }

  .prime{
    font-size: 9px;
    cursor: pointer;
  }

  .updated-ago{
    line-height: 13px;
    font-size: 9px;
  }
  .order-option{
    max-width: 136px;
  }
  .banner .bone.player {
    width: 100%;
    height: 69px;
  }

  .updated-ago > .text-truncate{
    max-width: 240px;
  }

}

/* Small devices */
@media (min-width: 375px) { 
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .container {
    max-width: 100% !important;
  }

  .card-columns {
    column-gap: 0.25rem;
  }
  
  .starring-seller{
    font-size: 11px;
  }

  .prime{
    font-size: 11px;
    cursor: pointer;
  }

  .updated-ago{
    line-height: 13px;
    font-size: 9px;
  }
  .order-option{
    max-width: 190px;
  }

  .banner .bone.player {
    width: 100%;
    height: 69px;
  }

  .updated-ago > .text-truncate{
    max-width: 120px;
  }


}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .container {
    max-width: 95% !important;
  }
  .card-columns {
    column-gap: 1.25rem;
  }

  .starring-seller{
  font-size: 0.8em;
  cursor: pointer;
  }

  .prime{
    font-size: 12px;
    cursor: pointer;
  }

  .updated-ago{
    line-height: 13px;
    font-size: 9px;
  }

  .order-option{
    max-width: unset;
  }

  .banner .bone.player {
    width: 100%;
    height: 135px;
  }

  .updated-ago > .text-truncate{
    max-width: 170px;
  }


}
 /* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .container {
    max-width: 95% !important;
  }
  .card-columns {
    column-gap: 1.25rem;
  }
  .starring-seller{
  font-size: 0.8em;
  cursor: pointer;
  }

  .prime{
    font-size: 0.8em;
    cursor: pointer;
  }

  .updated-ago{
    line-height: 13px;
    font-size: 10px;
  }

  .order-option{
    max-width: unset;
  }

  .banner .bone.player {
    width: 100%;
    height: 200px;
  }

  .updated-ago > .text-truncate{
    max-width: 210px;
  }

}

 /* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }

  .container {
    max-width: 95% !important;
  }

  .card-columns {
    column-gap: 1.25rem;
  }

  .starring-seller {
  font-size: 0.8em;
  cursor: pointer;
  }

  .prime{
    font-size: 0.8em;
    cursor: pointer;
  }

  .updated-ago {
    line-height: 13px;
    font-size: 10px;
  }

  .order-option {
    max-width: unset;
  }

  .banner .bone.player {
    width: 100%;
    height: 200px;
  }

  .updated-ago > .text-truncate{
    max-width: 165px;
  }

}

 /* Ultra large devices (1344px and up) */
@media (min-width: 1344px) {
  .card-columns {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .container {
    max-width: 95% !important;
  }
  .card-columns {
    column-gap: 1.25rem;
  }

  .starring-seller{
    font-size: 0.8em;
    cursor: pointer;
  }

  .prime{
    font-size: 0.8em;
    cursor: pointer;
  }

  .updated-ago{
    line-height: 13px;
    font-size: 10px;
  }

  .banner .bone.player {
    width: 100%;
    height: 200px;
  }

  .updated-ago > .text-truncate{
    max-width: 165px;
  }
}

/* Custom scroll */
/* example https://codepen.io/zkreations/pen/bZRgqd */
.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custom-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
}
.custom-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
}

.enable-scroll{
  overflow-x: auto; overflow-y: hidden;
}

.snap-type-x {
  scroll-snap-type: x mandatory;
}

/* .enable-scroll::before{
  background: linear-gradient(90deg,rgba(22,29,37,0),#fff);
  content: "";
  position: absolute;
  right: 0;
  top: 1px;
  bottom: 0;
  height: 100%;
  z-index: 1;
  width: 95px;
} */

/* Custom scroll */
/* example https://codepen.io/zkreations/pen/bZRgqd */

/* font utilities */

/* used to improve font readability into retina devices */
.font-regular, .f-g{
  font-weight: 400;
}

.font-bold{
  font-weight: 700;
}
/* typeahead styles */
.tt-suggestion>div,
.tt-notFound>div {
  padding: 0.5rem 0.75rem;
}

.tt-suggestion {
  position: relative;
  border-top: 1px solid #ccc;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.tt-suggestion>div {
  height: 64px;
}

.tt-list {
  border-color: #ccc !important;
  /* // dropdown shadow example https: //codepen.io/connexo/pen/gmWjNJ */
  box-shadow: 0 5px 13px #999;
  overflow-y: hidden !important;
}

.tt-list,
.tt-notFound {
  border-radius: 8px;
}

.typeahead-standalone .tt-list .tt-suggestion.tt-selected,
.typeahead-standalone .tt-list .tt-suggestion:hover {
  background-color: #f5f5f5 !important;
}

.typeahead-standalone .tt-list .tt-suggestion .tt-highlight {
  font-weight: 500 !important;
}

img.typeahead-item-preview {
  height: 40px;
  width: 40px;
}
/* typeahead styles */

.elipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.elipsis-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* card images size */
/* card images size */
@media all and (min-width: 1553px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 300px !important;
  }
}

@media all and (max-width: 1552px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 265px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media all and (max-width: 1382px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 234px !important;
  }
}

/* nest hub */
@media all and (max-width: 1024px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 190px !important;
  }
}


/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 172px !important;
  }
}

@media (max-width: 768px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 171.9px !important;
  }
}

@media (max-width: 720px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 280px !important;
  }
}

/* iPhone 6+ */
@media only screen and (max-width: 415px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 186px !important;
  }
}

/* iPhone 6 */
@media only screen and (max-width: 376px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 176.6px !important;
  }
}

/* iPhone 5 */
@media only screen and (max-width: 321px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 149px !important;
  }
}

/* iPhone 5 */
@media only screen and (max-width: 281px) {
  .card>.hand-cursor>.card-img,
  .card>.card-img-top {
    min-height: 129px !important;
  }
}

/* card images size */
/* card images size */