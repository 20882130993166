:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.repeat-1 {
  animation-iteration-count: var(--animate-repeat);
}

.animated.repeat-2 {
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animated.repeat-3 {
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animated.delay-1s {
  animation-delay: var(--animate-delay);
}

.animated.delay-2s {
  animation-delay: calc(var(--animate-delay) * 2);
}

.animated.delay-3s {
  animation-delay: calc(var(--animate-delay) * 3);
}

.animated.delay-4s {
  animation-delay: calc(var(--animate-delay) * 4);
}

.animated.delay-5s {
  animation-delay: calc(var(--animate-delay) * 5);
}

.animated.faster {
  animation-duration: calc(var(--animate-duration) / 2);
}

.animated.fast {
  animation-duration: calc(var(--animate-duration) * 0.8);
}

.animated.slow {
  animation-duration: calc(var(--animate-duration) * 2);
}

.animated.slower {
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
  .animated[class*=Out] {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounceIn {
  animation-duration: calc(var(--animate-duration) * 0.75);
  animation-name: bounceIn;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}
.fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}